import React from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import cardIcon from "../../assets/images/icons/basic-fact-card-icon.svg";

const factData = [
  {
    title: "Chapters",
    text: "The Quran consists of 114 Chapters, which is 19 x 6.",
  },
  {
    title: "Number of Verses",
    text: "The total number of verses in the Quran is 6346, or 19 x 334. Note that 6 + 3 + 4 + 6 = 19.",
  },
  {
    title: "The Word GOD",
    text: "The Word God (Allah) occurs in the Quran 2698 times and this number equals 19 X 142.",
  },
  {
    title: "Sum of Verses",
    text: "The Total sum of Verses numbers for all verses containing the Word GOD is 118123, also a multiple of 19 (118123 = 19 x 6217).",
  },
  {
    title: "The Word Quran",
    text: "The Word Quran is mentioned in the Quran 57 Times, which is equals to, 19 x 3.",
  },
  {
    title: "First Verse",
    text: "The First Verse known as Basmalah, consists of 19 Letters.",
  },
  {
    title: "The Proof",
    text: "The Word Proof is written throughout the entire Quran 19 Times.",
  },
  {
    title: "First Revelation",
    text: "The famous first revelation (96:1-5) consists of 19 words.",
  },
  {
    title: "19-Worded Revelation",
    text: "This 19-worded first revelation consists of 76 letters, or 19 x 4.",
  },
  {
    title: "Sura 96",
    text: "The first chapter revealed (Sura 96) consists of 19 verses.",
  },
  {
    title: "First Chapter",
    text: "This first chapter revealed is placed atop the last 19 chapters.",
  },
  {
    title: "First Chapter Letters",
    text: "This chapter also consists of 304 letters, or 19 x 16.",
  },
];

const BasicFactCard = ({ title, text }) => (
  <Card
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "background.paper",
      borderRadius: 2,
      boxShadow: 3,
      transition: "box-shadow 0.3s ease-in-out",
      "&:hover": {
        boxShadow: 6,
      },
    }}
  >
    <CardContent
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <img
        src={cardIcon}
        alt="basic facts icon"
        style={{ width: 48, height: 48, marginBottom: 2 }}
      />
      <Typography
        variant="body1"
        component="h3"
        gutterBottom
        sx={{ fontWeight: "bold", mb: 1 }}
      >
        {title}
      </Typography>
      <Typography variant="body2">{text}</Typography>
    </CardContent>
  </Card>
);

const BasicFactsCards = () => {
  return (
    <Box sx={{ py: 4, px: 3 }}>
      {" "}
      <Grid container spacing={4}>
        {factData.map((fact, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <BasicFactCard title={fact.title} text={fact.text} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BasicFactsCards;
