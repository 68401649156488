import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./index.scss";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Box className="footer_text_box">
          <Typography variant="p">
            " Those who have rejected the Quran’s proof when it came to them,
            have also rejected an Honorable book. [41:41] "
          </Typography>
        </Box>
        <Box className="footer_copytights">
          <Typography variant="p">
            Copyright &copy; 2023 MathMiracle.com. All rights reserved.
          </Typography>
        </Box>
      </footer>
    </>
  );
};

export default Footer;
