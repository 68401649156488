import React from 'react';
import {Link} from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import IntroImg from '../../assets/images/Intro-img.png';
import TheProofTabs from '../TheProofTabs';
import './index.scss';
 
const TheProof = () => {
  return (
    <>
        <Box className='proof_page_wrapper'>
            <Grid container spacing={4} className='proof_page_content '>
                <Grid item xs={12} sm={6} className="proof_page_text">
                    <Typography variant='h2'>The Quranic Initials</Typography>
                    <Typography variant='p'>
                            The Quran is characterized by a unique phenomenon never found in any other book; 29 chapters are prefixed with 14 different sets of "Quranic Initials," consisting of one to five letters per set. Fourteen letters, or half of the Arabic alphabet, participate in these initials. They collectively make up an interlocking mathematical system that is easily superhuman .These initials occur within their respective chapters, and also in combination with other initials in other chapters, in multiples of the number 19.
                            <br></br>
                            <br></br>
                            This mathematical system is easily superhuman. This is especially awesome in light of the fact that (1) no person was aware of its existence until recently and (2) it was revealed during the Dark Ages of Arabia before the existence of the modern number system.
                    </Typography>
                    <Box className='text-start list_link'>
                          <Link to="/" >List of Quarnic Initials and Their Suras</Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} className="proof_page_img">
                    <img src={IntroImg} alt='introduction-img'/>
                </Grid>
                
                <Box className='proof_page_tabs'>
                    <Typography variant='h2'>Quranic Initials throughout the Quran (From Appendix 1)</Typography>
                    <Typography variant='h4'>Click the tabs to see information on each set of initials</Typography>
                    <Box className='proof_tabs'>
                        <TheProofTabs/>
                    </Box>
                </Box>

            </Grid>
        </Box>
    </>
  )
}

export default TheProof