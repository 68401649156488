import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "./index.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#373F41",
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#3C81B938",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
    color: "#3C81B9",
    backgroundColor: "#ffffff",
  },
}));

export default function TheProofTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} className="proof_tabs_wrapper">
      <Box sx={{ borderBottom: 0 }} className="tabs_wrapper">
        <Tabs
          className="proofTabsCon"
          value={value}
          indicatorColor="none"
          onChange={handleChange}
          aria-label="basic tabs"
          centered
        >
          <Tab className="pageTab" label="Q" {...a11yProps(0)} />
          <Tab className="pageTab" label="N u N" {...a11yProps(1)} />
          <Tab className="pageTab" label="S" {...a11yProps(2)} />
          <Tab className="pageTab" label="Y.S." {...a11yProps(3)} />
          <Tab className="pageTab" label="H.M." {...a11yProps(4)} />
          <Tab className="pageTab" label="A.S.Q." {...a11yProps(5)} />
          <Tab className="pageTab" label="A.L.M." {...a11yProps(6)} />
          <Tab className="pageTab" label="A.L.R" {...a11yProps(7)} />
          <Tab className="pageTab" label="A.L.M.R." {...a11yProps(8)} />
          <Tab className="pageTab" label="A.L.M.S" {...a11yProps(9)} />
          <Tab className="pageTab" label="K.H.Y.A.S" {...a11yProps(10)} />
          <Tab
            className="pageTab"
            label="H.,T.H, T.S. &T.S.M"
            {...a11yProps(11)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box className="proof_tab_content">
          <Typography variant="h3">The Quranic Initial "Q" (Qaaf)</Typography>
          <Typography variant="p">
            Sura 50 is entitled "Q," prefixed with "Q," and the first verse
            reads, "Q, and the glorious Quran." This indicates that "Q" stands
            for "Quran," and the total number of Q's in the two Q-initialled
            suras represents the Quran's 114 suras (57+57 = 114 = 19x6). This
            idea is strengthened by the fact that "the Quran" occurs in the
            Quran 57 times.
            <br></br>
            <br></br>
            Sura 42 consists of 53 verses, and 42+53 = 95 = 19x5. Sura 50
            consists of 45 verses, and 50+45 = 95, same total as in Sura 42.
            Here is a summary of the Q-related data:
            <br></br>
            <br></br>
          </Typography>
          <ul className="miracles_list">
            <li className="list_item">
              The frequency of occurrence of "Q" in Sura "Q" (No. 50) is 57,
              19x3
            </li>
            <li className="list_item">
              The letter "Q" occurs in the other Q-initialed sura (No. 42)
              exactly the same number of times, 57,<br></br> 19x3.
            </li>
            <li className="list_item">
              The total occurrence of the letter "Q" in the two Q- initialled
              suras is 114, which equals the number of suras in the Quran.
            </li>
            <li className="list_item">
              "The Quran" is mentioned in the Quran 57 times, 19x3.
            </li>
            <li className="list_item">
              The description of the Quran as "Majid" (Glorious) is correlated
              with the frequency of occurrence of the letter "Q" in each of the
              Q-initialled suras. The word "Majid" has a gematrical value of 57
            </li>
            <li className="list_item">
              Sura 42 consists of 53 verses, and 42+53 is 95, or 19x5.
            </li>
            <li className="list_item">
              Sura 50 consists of 45 verses, and 50+45 is also 95, 19x5..
            </li>
            <li className="list_item">
              The number of Q's in all verses numbered "19" throughout the Quran
              is 76, 19x4.
            </li>
          </ul>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box className="proof_tab_content">
          <Typography variant="h3">Nun (Noon)</Typography>
          <Typography variant="p">
            This initial is unique; it occurs in one sura, 68, and the name of
            the letter is spelled out as three letters - Noon Wow Noon - in the
            original text, and is therefore counted as two N's. The total count
            of this letter in the N-initialed sura is 133, 19x7. The fact that
            "N" is the last Quranic Initial (see Table 1) brings out a number of
            special observations. For example, the number of verses from the
            first Quranic Initial (A.L.M. of 2:1) to the last initial (N. of
            68:1) is 5263, or 19x277.
            <br></br>
            <br></br>
            The word "God" (Allah) occurs 2641 (19x139) times between the first
            initial and the last initial. Since the total occurrence of the word
            "God" is 2698, it follows that its occurrence outside the initials
            "A.L.M." of 2:1 on one side, and the initial "N" of 68:1 on the
            other side, is 57, 19x3. Tables 9 to 18 prove that the initial "NuN"
            must be spelled out to show two N's.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box className="proof_tab_content">
          <Typography variant="h3">S (Saad)</Typography>
          <Typography variant="p">
            This initial prefixes three suras, 7, 19, and 38, and the total
            occurrence of the letter "S" (Saad) in these three suras is 152,
            19x8.
          </Typography>
          <TableContainer className="table_container">
            <Table sx={{ maxWidth: 279 }} aria-label="poof_facts_table table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left">Sura</StyledTableCell>
                  <StyledTableCell align="center">
                    Frequency of 'S'
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="left">07</StyledTableCell>
                  <StyledTableCell align="center">97</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">19</StyledTableCell>
                  <StyledTableCell align="center">26</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">38</StyledTableCell>
                  <StyledTableCell align="center">29</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center">
                    &nbsp;&nbsp;&nbsp; 152 (19 x 8)
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box className="proof_tab_content">
          <Typography variant="h3">Y.S. (Ya Seen)</Typography>
          <Typography variant="p">
            These two letters prefix Sura 36. The letter "Y" occurs in this sura
            237 times, while the letter "S" (Seen) occurs 48 times.
            <br></br>
            <br></br>
            The total of both letters is 285, 19x15.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Box className="proof_tab_content">
          <Typography variant="h3">H.M. (Ha Mim)</Typography>
          <Typography variant="p">
            Seven suras are prefixed with the letters "H " and "M ;" Suras 40
            through 46. The total occurrence of these two letters in the seven
            H.M.-initialled suras is 2147, or 19x113.
            <br></br>
            <br></br>
            Naturally, the alteration of a single letter "H" or "M" in any of
            the seven H.M.-initialled suras would have destroyed this intricate
            phenomenon.
          </Typography>
          <TableContainer className="table_container hm_table">
            <Table aria-label="poof_facts_table table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell sx={{ minWidth: 120 }} align="left">
                    Sura
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={3}>
                    Frequency of Occurence
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center">"H"</StyledTableCell>
                  <StyledTableCell align="center">"M"</StyledTableCell>
                  <StyledTableCell align="center">"H+M"</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="left">40</StyledTableCell>
                  <StyledTableCell align="center">64</StyledTableCell>
                  <StyledTableCell align="center">380</StyledTableCell>
                  <StyledTableCell align="center">444</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">41</StyledTableCell>
                  <StyledTableCell align="center">48</StyledTableCell>
                  <StyledTableCell align="center">276</StyledTableCell>
                  <StyledTableCell align="center">324</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">42</StyledTableCell>
                  <StyledTableCell align="center">53</StyledTableCell>
                  <StyledTableCell align="center">300</StyledTableCell>
                  <StyledTableCell align="center">353</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">43</StyledTableCell>
                  <StyledTableCell align="center">44</StyledTableCell>
                  <StyledTableCell align="center">324</StyledTableCell>
                  <StyledTableCell align="center">368</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">44</StyledTableCell>
                  <StyledTableCell align="center">16</StyledTableCell>
                  <StyledTableCell align="center">150</StyledTableCell>
                  <StyledTableCell align="center">166</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">45</StyledTableCell>
                  <StyledTableCell align="center">31</StyledTableCell>
                  <StyledTableCell align="center">200</StyledTableCell>
                  <StyledTableCell align="center">231</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">46</StyledTableCell>
                  <StyledTableCell align="center">36</StyledTableCell>
                  <StyledTableCell align="center">225</StyledTableCell>
                  <StyledTableCell align="center">261</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center">292</StyledTableCell>
                  <StyledTableCell align="center">1855</StyledTableCell>
                  <StyledTableCell align="center">2147</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="right" colSpan={4}>
                    (19 x 8)
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Box className="proof_tab_content">
          <Typography variant="h3">'A.S.Q. ('Ayn Seen Qaf)</Typography>
          <Typography variant="p">
            These initials constitute Verse 2 of Sura 42, and the total
            occurrence of these letters in this sura is 209, or{" "}
            <strong>19x11</strong>. The letter "'A" ('Ayn) occurs 98 times, the
            letter "S" (Seen) occurs 54 times, and the letter "Q" (Qaf) occurs
            57 times.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Box className="proof_tab_content">
          <Typography variant="h3">A.L.M.( Alif Laam Mim)</Typography>
          <Typography variant="p">
            The letters "A," "L," and "M" are the most frequently used letters
            in the Arabic language, and in the same order as we see in the
            Quranic Initials - "A," then "L," then "M." These letters prefix six
            suras - 2, 3, 29, 30, 31, and 32 - and the total occurrence of the
            three letters in each of the six suras is a multiple of 19 [9899
            (19x521), 5662 (19x298), 1672 (19x88), 1254 (19x66), 817 (19x43),
            and 570 (19x30), respectively]. Thus, the total occurrence of the
            three letters in the six suras is 19874 (19x1046), and the
            alteration of one of these letters destroys this phenomenon.
          </Typography>
          <TableContainer className="table_container hm_table">
            <Table aria-label="poof_facts_table table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell sx={{ minWidth: 80 }} align="left">
                    Sura
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={5}>
                    Frequency of Occurence
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center">"A"</StyledTableCell>
                  <StyledTableCell align="center">"L"</StyledTableCell>
                  <StyledTableCell align="center">"M"</StyledTableCell>
                  <StyledTableCell align="center">Total</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="left">02</StyledTableCell>
                  <StyledTableCell align="center">64</StyledTableCell>
                  <StyledTableCell align="center">380</StyledTableCell>
                  <StyledTableCell align="center">380</StyledTableCell>
                  <StyledTableCell align="center">444</StyledTableCell>
                  <StyledTableCell align="center">(19x521)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">03</StyledTableCell>
                  <StyledTableCell align="center">48</StyledTableCell>
                  <StyledTableCell align="center">276</StyledTableCell>
                  <StyledTableCell align="center">276</StyledTableCell>
                  <StyledTableCell align="center">324</StyledTableCell>
                  <StyledTableCell align="center">(19x521)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">29</StyledTableCell>
                  <StyledTableCell align="center">53</StyledTableCell>
                  <StyledTableCell align="center">300</StyledTableCell>
                  <StyledTableCell align="center">300</StyledTableCell>
                  <StyledTableCell align="center">353</StyledTableCell>
                  <StyledTableCell align="center">(19x521)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">30</StyledTableCell>
                  <StyledTableCell align="center">44</StyledTableCell>
                  <StyledTableCell align="center">324</StyledTableCell>
                  <StyledTableCell align="center">324</StyledTableCell>
                  <StyledTableCell align="center">368</StyledTableCell>
                  <StyledTableCell align="center">(19x521)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">44</StyledTableCell>
                  <StyledTableCell align="center">16</StyledTableCell>
                  <StyledTableCell align="center">150</StyledTableCell>
                  <StyledTableCell align="center">150</StyledTableCell>
                  <StyledTableCell align="center">166</StyledTableCell>
                  <StyledTableCell align="center">(19x521)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">45</StyledTableCell>
                  <StyledTableCell align="center">31</StyledTableCell>
                  <StyledTableCell align="center">200</StyledTableCell>
                  <StyledTableCell align="center">200</StyledTableCell>
                  <StyledTableCell align="center">231</StyledTableCell>
                  <StyledTableCell align="center">(19x521)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">46</StyledTableCell>
                  <StyledTableCell align="center">36</StyledTableCell>
                  <StyledTableCell align="center">225</StyledTableCell>
                  <StyledTableCell align="center">225</StyledTableCell>
                  <StyledTableCell align="center">261</StyledTableCell>
                  <StyledTableCell align="center">(19x521)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center" className="fw-bold">
                    292
                  </StyledTableCell>
                  <StyledTableCell align="center" className="fw-bold">
                    1855
                  </StyledTableCell>
                  <StyledTableCell align="center" className="fw-bold">
                    1855
                  </StyledTableCell>
                  <StyledTableCell align="center" className="fw-bold">
                    2147
                  </StyledTableCell>
                  <StyledTableCell align="center" className="fw-bold">
                    (19x521)
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="right" colSpan={6}>
                    &nbsp;
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Box className="proof_tab_content">
          <Typography variant="h3">A.L.R. (Alef Laam Ra)</Typography>
          <Typography variant="p">
            These initials are found in Suras 10, 11, 12, 14, and 15. The total
            occurrences of these letters in these suras are 2489 (19x131), 2489
            (19x131), 2375 (19x 125), 1197 (19x63), and 912 (19x48),
            respectively.
          </Typography>
          <TableContainer className="table_container hm_table">
            <Table aria-label="poof_facts_table table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell sx={{ minWidth: 80 }} align="left">
                    Sura
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={5}>
                    Frequency of Occurence
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">10</StyledTableCell>
                  <StyledTableCell align="center">"A"</StyledTableCell>
                  <StyledTableCell align="center">"L"</StyledTableCell>
                  <StyledTableCell align="center">"R"</StyledTableCell>
                  <StyledTableCell align="center">Total</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="left">10</StyledTableCell>
                  <StyledTableCell align="center">1319</StyledTableCell>
                  <StyledTableCell align="center">913</StyledTableCell>
                  <StyledTableCell align="center">257</StyledTableCell>
                  <StyledTableCell align="center">2489</StyledTableCell>
                  <StyledTableCell align="center">(19x131)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">11</StyledTableCell>
                  <StyledTableCell align="center">1370</StyledTableCell>
                  <StyledTableCell align="center">794</StyledTableCell>
                  <StyledTableCell align="center">325</StyledTableCell>
                  <StyledTableCell align="center">2489</StyledTableCell>
                  <StyledTableCell align="center">(19x131)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">12</StyledTableCell>
                  <StyledTableCell align="center">1306</StyledTableCell>
                  <StyledTableCell align="center">812</StyledTableCell>
                  <StyledTableCell align="center">257</StyledTableCell>
                  <StyledTableCell align="center">2375</StyledTableCell>
                  <StyledTableCell align="center">(19x125)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">14</StyledTableCell>
                  <StyledTableCell align="center">585</StyledTableCell>
                  <StyledTableCell align="center">452</StyledTableCell>
                  <StyledTableCell align="center">160</StyledTableCell>
                  <StyledTableCell align="center">1197</StyledTableCell>
                  <StyledTableCell align="center">(19x63)</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">15</StyledTableCell>
                  <StyledTableCell align="center">493</StyledTableCell>
                  <StyledTableCell align="center">323</StyledTableCell>
                  <StyledTableCell align="center">96</StyledTableCell>
                  <StyledTableCell align="center">912</StyledTableCell>
                  <StyledTableCell align="center">(19x48)</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center">5073</StyledTableCell>
                  <StyledTableCell align="center">3294</StyledTableCell>
                  <StyledTableCell align="center">1095</StyledTableCell>
                  <StyledTableCell align="center">9462</StyledTableCell>
                  <StyledTableCell align="center">(19x498)</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Box className="proof_tab_content">
          <Typography variant="h3">A.L.M.R. (Alef Laam Mim Ra)</Typography>
          <Typography variant="p">
            These initials prefix one sura, No. 13, and the total frequency of
            occurrence of the four letters is 1482, or 19x78. The letter "A"
            occurs 605 times, "L" occurs 480 times, "M" occurs 260 times, and
            "R" occurs 137 times.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Box className="proof_tab_content">
          <Typography variant="h3">A.L.M.S. (Alef Laam Mim Saad)</Typography>
          <Typography variant="p">
            Only one sura is prefixed with these initials, Sura 7, and the
            letter "A" occurs in this sura 2529 times, "L" occurs 1530 times,
            "M" occurs 1164 times, and "S" (Saad) occurs 97 times. Thus, the
            total occurrence of the four letters in this sura is 2529 + 1530 +
            1164 + 97 = 5320 = 19x280.
            <br></br>
            An important observation here is the interlocking relationship
            involving the letter "S" (Saad). This initial occurs also in Suras
            19 and 38. While complementing its sister letters in Sura 7 to give
            a total that is divisible by 19, the frequency of this letter also
            complements its sister letters in Suras 19 and 38 to give a multiple
            of 19.
            <br></br>
            Additionally, the Quranic Initial "S" (Saad) interacts with the
            Quranic Initials "K.H.Y. `A." (Kaaf Haa Ya `Ayn) in Sura 19 to give
            another total that is also a multiple of 19. This interlocking
            relationship - which is not unique to the initial "S" (Saad) -
            contributes to the intricacy of the Quran's numerical code.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={10}>
        <Box className="proof_tab_content">
          <Typography variant="h3">
            K.H.Y.'A.S. (Kaaf Ha Ya 'Ayn Saad)
          </Typography>
          <Typography variant="p">
            This is the longest set of initials, consisting of five letters, and
            it occurs in one sura, Sura 19. The letter "K" in Sura 19 occurs 137
            times, "H" occurs 175 times, "Y" occurs 343 times, "'A" occurs 117
            times, and "S" (Saad) occurs 26 times. Thus, the total occurrence of
            the five letters is
            <br></br>
            <br></br>
            137 + 175 + 343 + 117 + 26 = 798 = 19x42.
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={11}>
        <Box className="proof_tab_content">
          <Typography variant="h3">
            H., T.H. (Ta Ha), T.S. (Ta Seen), & T.S.M. (Ta Seen Mim)
          </Typography>
          <Typography variant="p">
            An intricate interlocking relationship links these overlapping
            Quranic Initials to produce a total that is also a multiple of 19.
            The initial "H." is found in Suras 19 and 20. The initials "T.H."
            prefix Sura 20. The initials "T.S." are found in Sura 27, while the
            initials "T.S.M." prefix its surrounding Suras 26 & 28.
            <br></br>
            <br></br>
            It should be noted at this time that the longer, more complex,
            interlocking and overlapping initials are found in the suras where
            uncommonly powerful miracles are narrated. For example, the virgin
            birth of Jesus is given in Sura 19, which is prefixed with the
            longest set of initials, K.H.Y.'A.S.
            <br></br>
            <br></br>
            The interlocking initials "H.," "T.H.," "T.S.," and "T.S.M." prefix
            suras describing the miracles of Moses, Jesus, and the uncommon
            occurrences surrounding Solomon and his jinns. God thus provides
            stronger evidence to support stronger miracles.
          </Typography>
          <TableContainer className="table_container hm_table">
            <Table aria-label="poof_facts_table table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell sx={{ minWidth: 80 }} align="left">
                    Sura
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={4}>
                    Frequency of Occurence
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center">"H"</StyledTableCell>
                  <StyledTableCell align="center">"T"</StyledTableCell>
                  <StyledTableCell align="center">"S"</StyledTableCell>
                  <StyledTableCell align="center">"M"</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="left">19</StyledTableCell>
                  <StyledTableCell align="center">175</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">20</StyledTableCell>
                  <StyledTableCell align="center">251</StyledTableCell>
                  <StyledTableCell align="center">28</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">26</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">33</StyledTableCell>
                  <StyledTableCell align="center">94</StyledTableCell>
                  <StyledTableCell align="center">484</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">27</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">27</StyledTableCell>
                  <StyledTableCell align="center">94</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left">28</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">19</StyledTableCell>
                  <StyledTableCell align="center">102</StyledTableCell>
                  <StyledTableCell align="center">460</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="center">426</StyledTableCell>
                  <StyledTableCell align="center">107</StyledTableCell>
                  <StyledTableCell align="center">290</StyledTableCell>
                  <StyledTableCell align="center">944</StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="right" colSpan={5}>
                    &nbsp;426 + 107 + 290 + 944 = 1767 = (19x93)
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </TabPanel>
    </Box>
  );
}
