import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import IntroImg from "../../assets/images/Intro-img.png";
import BasicFactsCards from "../BasicFactsCards";
import "./index.scss";

const BasicFacts = () => {
  return (
    <>
      <Box className="basicfacts_page_wrapper">
        <Grid container spacing={4} className="basicfacts_page_content ">
          <Grid item xs={12} sm={6} className="basicfacts_page_text">
            <Typography variant="h2">
              Simple to Understand, Impossible to Imitate
            </Typography>
            <Typography variant="p">
              God Informs us in Chapter 74 that the authenticity of Quran will
              be proven by the number of 19. Verse 35 describes this 19-bases
              mathematical code as “One of the Great miracles”
              <br></br>
              <br></br>
            </Typography>
            <Typography variant="p" className="text-center">
              “ Absolutely, ( I swear) by the moon and the night as it passes
              and the morning as it shines “<br></br>
              Chapter 74-The Hidden Secret
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className="basicfacts_page_img">
            <img src={IntroImg} alt="introduction-img" />
          </Grid>

          <Grid
            item
            xs={12}
            className="basicfacts_cards_section"
            sx={{ px: 2 }}
          >
            <Box>
              <Typography variant="h2" className="text-center">
                Basic Facts
              </Typography>
            </Box>
            <Box
              className="basic_facts_cards_wrapper"
              sx={{ overflow: "visible" }}
            >
              <BasicFactsCards />
            </Box>
          </Grid>
          <Grid item xs={12} className="superhuman_section p-0">
            <Box className="section_title">
              <Typography variant="h2" className="text-center">
                Superhuman Mathematical Design
              </Typography>
              <Typography variant="p" className="text-center">
                Now that we have grasped a few of the basic facts, let us
                examine the truly miraculous design of the mysterious "Quranic
                Initials"
              </Typography>
            </Box>
            <Box className="smd_cards_wrapper">
              <Grid container spacing={6} className="smd_cards">
                <Grid item md={4} className="smd_card">
                  <Box className="card_content">
                    <Typography variant="h5" className="card_title text-start">
                      Chapter 10
                    </Typography>
                    <Typography variant="p" className="card_text text-start">
                      A. L. R. These (letters) are the{" "}
                      <Typography
                        component="span"
                        fontWeight="bold"
                        color="primary"
                      >
                        proofs
                      </Typography>{" "}
                      of this book of wisdom
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} className="smd_card">
                  <Box className="card_content">
                    <Typography variant="h5" className="card_title text-start">
                      Chapter 28
                    </Typography>
                    <Typography variant="p" className="card_text text-start">
                      T. S. M these letters constitute{" "}
                      <Typography
                        component="span"
                        fontWeight="bold"
                        color="primary"
                      >
                        proofs
                      </Typography>{" "}
                      of this profound book.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} className="smd_card">
                  <Box className="card_content">
                    <Typography variant="h5" className="card_title text-start">
                      Chapter 38
                    </Typography>
                    <Typography variant="p" className="card_text text-start">
                      S., and the Quran that contains the{" "}
                      <Typography
                        component="span"
                        fontWeight="bold"
                        color="primary"
                      >
                        proof
                      </Typography>
                      .
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BasicFacts;
