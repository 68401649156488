import * as React from 'react';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Introduction from '../Introduction/index'
import BasicFacts from '../BasicFacts/index';
import TheProof from '../TheProof';
import TheMessage from '../TheMessage';
import ContactUs from '../ContactUs';
import './index.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PageTabs() {
  const [value, setValue] = React.useState(0);
 
  const changeTab = (e) => {
    
    const Introduction = document.getElementById("simple-tab-0")
    const Basic_Facts = document.getElementById("simple-tab-1")
    const The_Proof = document.getElementById("simple-tab-2")
    const The_Message = document.getElementById("simple-tab-3")
    const Contact_us = document.getElementById("simple-tab-4")
    e.preventDefault()

    let buttonTab = e.target.getAttribute('id')
    if(buttonTab === 'Introduction'){ 
      Introduction.click()
    }
    if(buttonTab === 'Basic_Facts'){ 
      Basic_Facts.click()
    }
    else if(buttonTab === 'The_Proof'){
      The_Proof.click()
    }
    else if(buttonTab === 'The_Message'){
      The_Message.click()
    }
    else if(buttonTab === 'Contact_us'){
      Contact_us.click()
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function changeTabsBG(){
    const TabButtons = document.querySelectorAll(".pageTab")
    TabButtons.forEach(function(elem){
      elem.addEventListener("click", function(e){
        setTimeout(() => {
          for(var j = 0; j<TabButtons.length; j++){
              TabButtons[j].classList.remove("active")
          }
          for(var i = 0; i<TabButtons.length; i++){
              TabButtons[i].classList.add("active")
              if(TabButtons[i].classList.contains("Mui-selected")){
                  return
              }
          }
        }, 10);
      })
    })
  }
  

  useEffect(() => {
    changeTabsBG();
  }, []);

  return (
    <Box sx={{ width: '100%' }} className="tabs_page_wrapper">
      <Box sx={{ borderBottom: 0 }} className="tabs_wrapper">
        <Tabs className='pageTabsCon' value={value} indicatorColor="none"
        onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab  className='pageTab' label="Introduction" {...a11yProps(0)} />
          <Tab className='pageTab' label="Basic Facts" {...a11yProps(1)} />
          <Tab className='pageTab' label="The Proof" {...a11yProps(2)} />
          <Tab className='pageTab' label="The Message" {...a11yProps(3)} />
          <Tab className='pageTab' label="Contact Us" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="tab_container">
        <Introduction />
        <Box className='d-flex flex-wrap justify-content-end align-items-center gap-2 container link_container'>
            <Link to="/">
              <span className='me-1'>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7,13L1,7l6-6" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
              Return Home
            </Link>
            <Box className="divider" />
            <Link to="/Basic_Facts" id="Basic_Facts" onClick={changeTab} > 
                Click Here to Read More Basic Facts
                <span className='ms-2'>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </Link>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1} className="tab_container">
        <BasicFacts />
        <Box className='d-flex flex-wrap justify-content-end align-items-center gap-2 container link_container readmore_link'>
          <Link to="/Introduction" id='Introduction' onClick={changeTab}>
              <span className='me-1'>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7,13L1,7l6-6" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
              Introduction
          </Link>
          <Box className="divider" />
          <Link to="/The_Proof" id='The_Proof' onClick={changeTab}>
              Click Here to See the Proof
              <span className='ms-1'>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
          </Link>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2} className="tab_container">
        <TheProof/>
        <Box className='d-flex flex-wrap justify-content-end align-items-center gap-2 container link_container message_link'>
          <Link to="/Basic_Facts" id='Basic_Facts' onClick={changeTab}>
              <span className='me-1'>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7,13L1,7l6-6" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
              Basic Facts
          </Link>
          <Box className="divider" />
            <Link to="/The_Message" id='The_Message' onClick={changeTab}>
                Click Here to see the Message
                <span className='ms-2'>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </Link>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3} className="tab_container">
        <TheMessage/>
        <Box className='d-flex flex-wrap justify-content-end align-items-center gap-2 container link_container message_link'>
          <Link to="/The_Proof" id='The_Proof' onClick={changeTab}>
              <span className='me-1'>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7,13L1,7l6-6" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
              The Proof
          </Link>
          <Box className="divider" />
            <Link to="/Contact_us" id='Contact_us' onClick={changeTab}>
                Click Here to Get in Touch With Us
                <span className='ms-2'>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </Link>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={4} className="tab_container">
        <ContactUs/>
        <Box className='d-flex flex-wrap justify-content-end align-items-center gap-2 container link_container message_link'>
            <Link to="/The_Message" id='The_Message' onClick={changeTab}>
              <span className='me-1'>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7,13L1,7l6-6" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
                Click Here to see the Message
            </Link>
        </Box>
      </TabPanel>
    </Box>
  );
}