import React from 'react';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './index.scss';

const index = () => {
  return (
      <Box className='home_wrapper'>
        <div className='home_content'>
          <div className='site_logo'>
            <Link to="/">
                <Typography variant="h1">
                    Math <span>Miracle</span>
                </Typography>
                <Typography variant="h5">
                    A message from <span>GOD</span>
                </Typography>
              </Link>
          </div>
          <div className='home_banner_text'>
            <Box className='banner_text'>
              <Typography variant="h4">
                The Time has come for you to
              </Typography>
              <Typography variant="h2">
                Witness a&nbsp;<span>Miracle</span>
              </Typography>;
              <Link to="/start" className='next_page_link'>
                Are You Ready?
                <span className='link_icon ms-2'>
                  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.21181 19.339C1.82442 19.7086 1.21462 19.7075 0.82857 19.3365L0.753406 19.2642C0.342776 18.8696 0.344173 18.2122 0.756476 17.8193L8.20232 10.7239C8.61584 10.3299 8.61584 9.6701 8.20232 9.27605L0.756478 2.18072C0.344174 1.78783 0.342776 1.13042 0.753406 0.735778L0.82857 0.663541C1.21462 0.292518 1.82442 0.29141 2.21181 0.661027L11.2417 9.27647C11.6546 9.67044 11.6546 10.3295 11.2417 10.7235L2.21181 19.339Z" fill="white"/>
                  </svg>
                </span>
              </Link>
            </Box>
          </div>
        </div>
      </Box>
  )
}

export default index