import React from 'react';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PageTabs from '../Tabs/index';
import Footer from '../footer';
import './index.scss';

const index = () => {
  return (
    <>
        <Box className='readmore_page_wrapper'>
            <div className='readmore_page_content'>
                <div className='site_logo'>
                    <Link to="/">
                        <Typography variant="h1">
                            Math <span>Miracle</span>
                        </Typography>
                        <Typography variant="h5">
                            A message from <span>GOD</span>
                        </Typography>
                    </Link>
                </div>
                <div className='page_tabs_con'>
                    <PageTabs/>
                </div>
            </div>
        </Box>
        <Footer />
    </>
  )
}

export default index