import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IntroImg from '../../assets/images/Intro-img.png';
import FactsCarousel from '../FactsCarousel/index';
import './index.scss';

const Introduction = () => {
  return (
        <Box className='intro_page_wrapper'>
            <Grid container spacing={4} className='intro_page_content m-0'>
                <Grid item xs={12} sm={6} className="intro_page_item intro_page_text">
                    <Typography variant='h2'>The First Physical Evidence proving the existence of GOD</Typography>
                        <Typography variant='p'>
                            There now exists physical evidence for a message from God to the world. This marks the advent of a new era in religion; an era where FAITH is no longer needed. There is no need to "believe," when one "knows." People of the past generations were required to believe in God, and uphold His commandments ON FAITH. 
                            <br></br>
                            <br></br>
                            With the advent of the physical evidence reported in this book, we no longer believe that God exists; we KNOW that God exists. Such knowledge is ascertained through God's final scripture, Quran, wherein overwhelming physical evidence has been encoded. Employing the ultimate in scientific proof, namely, mathematics, the evidence comes in the form of an extremely intricate code. Thus, every word, indeed every letter in Quran is placed in accordance with a mathematical design that is clearly beyond human ability.
                            <br></br>
                            <br></br>
                            Not only does the evidence prove the authenticity and perfect preservation of the Qur'an, but it also confirms the miracles of previous messengers including Noah, Abraham, Moses, David, and Jesus. None of us witnessed the parting of the Red Sea, or the virgin birth of Christ. However, upon reviewing the evidence presented here, and examining the appropriate narrations, the reader will be as positively certain as an eyewitness.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="intro_page_item intro_page_img ps-md-3 ps-lg-5">
                    <img src={IntroImg} alt='introduction-img' className='w-100'/>

                    <Box className="intro_page_slider_con">
                        <Typography variant='h5'>Here are a few basic facts</Typography>
                        <Box className="intro_page_slider">
                            <FactsCarousel />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
  )
}

export default Introduction