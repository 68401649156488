import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/index";
import Start from "./components/Start/index";
import ReadMore from "./components/ReadMore/index";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          p: "p",
          span: "span",
          label: "label",
        },
      },
    },
  },
});
function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/start" element={<Start />} />
          <Route path="/read-more" element={<ReadMore />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
