import React from 'react';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
// import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import './index.scss';

const index = () => {
  return (
        // <Fade in={true} timeout={1000}>
            <Box className='start_wrapper'>
                <div className='start_content'>
                <div className='site_logo'>
                    <Link to="/">
                        <Typography variant="h1">
                            Math <span>Miracle</span>
                        </Typography>
                        <Typography variant="h5">
                            A message from <span>GOD</span>
                        </Typography>
                    </Link>
                </div>
                <div className='start_banner_text'>
                    <Box className='banner_text'>
                    <Typography variant="h3">Witness a <span>Miracle</span></Typography>
                    <Typography variant="h6">
                        There now exists physical evidence for a message from God to the world. This marks the advent of a new era in religion; an era where FAITH is no longer needed. There is no need to "believe," when one "knows." People of the past generations were required to believe in God, and uphold His commandments ON FAITH. With the advent of the physical evidence reported on this site, we no longer believe that God exists; we KNOW that God exists.
                    </Typography>
                    <Link to="../read-more" className='continue_btn' >
                        Click Here to Continue Reading
                        <span className='link_icon ms-2'>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                    </Link>
                    </Box>
                </div>
                </div>
            </Box>
        // </Fade>
  )
}

export default index