import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import YTIcon from "../../assets/images/icons/youtube.svg";
import FBIcon from "../../assets/images/icons/facebook.svg";
import InstaIcon from "../../assets/images/icons/instagram.svg";
import LiIcon from "../../assets/images/icons/linkedin.svg";
import WebIcon from "../../assets/images/icons/web.svg";
import FollowIcon from "../../assets/images/icons/follow.svg";
import EmailIcon from "../../assets/images/icons/email.svg";
import "./index.scss";

const ContactUs = () => {
  return (
    <>
      <Box className="contact_page_wrapper">
        <Box className="page_title">
          <Typography variant="h3" className="text-center">
            Contact Us
          </Typography>
          <Typography variant="p" className="text-center">
            Reach out to us for more information
          </Typography>
        </Box>

        <Box className="page_content">
          <Box className="contact_item">
            <Box className="item_icon">
              <a
                href="https://www.quranalone.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={WebIcon} alt="" />
              </a>
            </Box>
            <Typography variant="h4">
              <a
                href="https://www.quranalone.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                QuranAlone.com
              </a>
            </Typography>
            <Typography variant="p">
              Visit us and find out more information
            </Typography>
          </Box>
          <Box className="contact_item">
            <Box className="item_icon">
              <a
                href="mailto:worshipGodalone@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={EmailIcon} alt="" />
              </a>
            </Box>
            <Typography variant="h4">
              <a
                href="mailto:worshipGodalone@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                WorshipGodAlone@gmail.com
              </a>
            </Typography>
            <Typography variant="p">
              Email us to find out more information
            </Typography>
          </Box>
          <Box className="contact_item">
            <Box className="item_icon">
              <img src={FollowIcon} alt="" />
            </Box>
            <Box className="social_links">
              <Box className="social_icon">
                <Link
                  to="https://www.youtube.com/@submittercommunity3102"
                  target="_blank"
                >
                  <img src={YTIcon} alt="" />
                </Link>
              </Box>
              <Box className="social_icon">
                <Link
                  to="https://www.facebook.com/groups/578002032639587"
                  target="_blank"
                >
                  <img src={FBIcon} alt="" />
                </Link>
              </Box>
              {/* <Box className="social_icon">
                <Link to="#">
                  <img src={LiIcon} alt="" />
                </Link>
              </Box>
              <Box className="social_icon">
                <Link to="#">
                  <img src={InstaIcon} alt="" />
                </Link>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
