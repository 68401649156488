import React from 'react';
import {Link} from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import './index.scss';

const TheMessage = () => {
  return (
    <>
        <Box className='message_page_wrapper'>
            <Box className='page_title'>
                <Typography variant='h2'>Proclaiming one unified religion for all the people</Typography>
                <Typography variant='h5'>
                    All religions of the world - Judaism, Christianity, Islam, Hinduism, Buddhism, and others - have been severely corrupted through innovations, traditions, and the idolization of humans such as the prophets and the saints.
                </Typography>
            </Box>
            <Box className='message_page_content '>
                <Typography variant='p'>
                God's plan, as stated in the Old Testament (Malachi 3:1), the New Testament (Luke 17:22-36 & Matthew 24:27), and this Final Testament (3:81), calls for the sending of God's Messenger of the Covenant after all the scriptures have been delivered. The main function of God's Messenger of the Covenant is to purify the scriptures and unify them into one universal message to this world from the Creator and Sustained of this world.
                <br></br><br></br>
                This major scriptural prophecy has now been fulfilled. God's Messenger of the Covenant has arrived, supported by overwhelming tangible proof (see Appendix Two). The purification and unification process has begun. God's plan is supported by God's invisible forces, and the enormous dimensions of this divine plan is manifest in the recent exposure of false religionists, and the removal of such anti-freedom barriers as the Berlin Wall, the Iron Curtain, and the bamboo curtain.
                <br></br>
                Henceforth, there is only one religion acceptable to God - Submission. Anyone who submits to God and devotes the worship to God ALONE is a "Submitter." Thus, one may be a Jewish Submitter, a Christian Submitter, a Buddhist Submitter, a Hindu Submitter, or a Muslim Submitter.

                </Typography>
                <ul>
                    <li>The only religion acceptable to God is Submission. [3:19]</li>
                    <li>Anyone who seeks other than submission as his religion it will not be accepted from him and in the Hereafter he will be the losers. [3:85]</li>
                </ul>

                <Typography variant='p' className='author_name text-end'>- Rashad Khalifa, November, 1989</Typography>

                <Link to="https://www.quranalone.com/" className='btn btn-primary'>
                    Get More Information at Quran Alone
                </Link>
            </Box>
        </Box>
    </>
  )
}

export default TheMessage