import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles";
import "./index.scss";

const facts = [
  <>
    God states in Chapter 74 verse 30 that the authenticity of the Quran will be
    proven by the number{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      19
    </Typography>
    .
  </>,
  <>
    God informs us in chapter 83 verse 20 that the Quran is a{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      "numerically structured book."
    </Typography>
  </>,
  <>
    The word{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      "day"
    </Typography>{" "}
    occurs throughout the entire Quran{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      365
    </Typography>{" "}
    times.
  </>,
  <>
    The word{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      "month"
    </Typography>{" "}
    occurs throughout the entire Quran{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      12
    </Typography>{" "}
    times.
  </>,
  <>
    The mathematical structure of the Quran is based on the number{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      19
    </Typography>
    , which represents God as the{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      Alpha (1)
    </Typography>{" "}
    and the{" "}
    <Typography
      component="span"
      fontWeight="bold"
      color="primary"
      display="inline"
    >
      Omega (9)
    </Typography>
    .
  </>,
];

export default function FactsCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? facts.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === facts.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Box
      className="facts_carousel"
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "200px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        padding: theme.spacing(3),
      }}
    >
      <IconButton
        onClick={handlePrev}
        sx={{ position: "absolute", left: theme.spacing(1) }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box
        className="carousel_item"
        sx={{ textAlign: "center", maxWidth: "80%" }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            lineHeight: 1.5,
            color: theme.palette.text.primary,
          }}
        >
          {facts[currentIndex]}
        </Typography>
      </Box>
      <IconButton
        onClick={handleNext}
        sx={{ position: "absolute", right: theme.spacing(1) }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
}
